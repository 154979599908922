import { useContext, useEffect, useState } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Nav, Badge } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { dateFilter, textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import WidgetCard from './../../components/Card/widget-card';
import Forecast from './../../components/Chart/forecast';
import DeviceSessions from './../../components/Chart/device-sessions';
import Acquisition from './../../components/Chart/acquisition';

import './theme.css';
import well from "../Well";
import { DataContext } from "../../context/DataContext";
import StatCards from "../../components/Card/stat-cards";
import {statusBadgeClasses} from "../../utils";

const JourneyDashboard = () => {

    const { env } = useContext(GeneralContext);
    const { setActiveRoute } = useContext(DataContext);
    const { axios } = useContext(GeneralContext);

    const { state } = useLocation();
    const navigate = useNavigate();


    const [fullJourneysData, setFullJourneysData] = useState([]);

    const rnmin = (min, max) => (Math.random() * (max - min) + min);
    const rn = (max) => (Math.floor(Math.random() * max));

    const forecast = [
        {
            key: '2019',
            "In Transit": rnmin(10, 20).toFixed(0),
            "Delivered": rnmin(1100, 5000).toFixed(0),
        },
        {
            key: '2020',
            "In Transit": rnmin(10, 20).toFixed(0),
            "Delivered": rnmin(1100, 5000).toFixed(0),
        },
        {
            key: '2021',
            "In Transit": rnmin(10, 20).toFixed(0),
            "Delivered": rnmin(1100, 5000).toFixed(0),
        },
        {
            key: '2022',
            "In Transit": rnmin(10, 20).toFixed(0),
            "Delivered": rnmin(1100, 5000).toFixed(0),
        },
        {
            key: '2023',
            "In Transit": rnmin(10, 20).toFixed(0),
            "Delivered": rnmin(1100, 5000).toFixed(0),
        }
    ];

    const acquisition = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        .map(day => ({
            day,
            lock: rnmin(400, 550).toFixed(0),
            unlock: rnmin(400, 550).toFixed(0),
        }))

    const deviceSessions = [
        {
            day: 'October',
            emitted: rnmin(800, 900).toFixed(0),
        },
        {
            day: 'November',
            emitted: rnmin(500, 900).toFixed(0),
        },
        {
            day: 'December',
            emitted: rnmin(400, 600).toFixed(0),
        }
    ];

    const columns = [
        {
            dataField: 'data',
            text: 'Journey',
            sort: true,
            formatter: (data, index) => {
                return <div className={'d-flex align-items-center'}>
                    <div className={'d-flex flex-column ml-4 break-all'}>
                        <Nav.Link className="text-info p-0"
                            title={`View journey-${data?.journey.id} details`}
                            onClick={() => {
                                navigate(`/journey-data`, { state: { [`journeyId`]: `${data?.journey.id}` } });
                                setActiveRoute(`journey`);
                            }}>
                            {`journey-${data?.journey.id}`}
                        </Nav.Link>
                        <small>{data?.journey.txid}</small>
                    </div>
                </div>;
            },
        },
        {
            dataField: 'data.consignment.reference',
            text: 'Reference',
            sort: true,
            formatter: (data, index) => {
                return <div className={'d-flex flex-column gap'}>
                    <span>{data ?? null}</span>
                </div>;
            },
            filter: textFilter(),
        },
        {
            dataField: 'data.journey.journeyStatus',
            text: 'Status',
            sort: true,
            formatter: (journeyStatus, index) => {
                const variant = (statusBadgeClasses[journeyStatus] ?? "dark").replace("badge-", "");
                return (
                    <Badge className='text-center' key='to-be-proccessed' bg={variant}>
                        {journeyStatus ?? null}
                    </Badge>
                )
            },
            filter: selectFilter({
                options: Object.keys(statusBadgeClasses).filter(key => key.length > 1).map((key) => {
                    return {
                        value: key,
                        label: key
                    }
                })
            })
        },
        {
            dataField: 'data',
            text: 'Suppliers',
            sort: true,
            formatter: (data, index) => {
                return data?.consignment.suppliers ? (
                    data?.consignment.suppliers.map(({ company_name }, index) => (company_name)).join(', ')
                ) : <></>
            }
        },
        {
            dataField: 'data',
            text: 'Customer',
            sort: true,
            formatter: (data, index) => {
                return data.consignment.customer?.company_name
            }
        },
        {
            dataField: 'data',
            text: 'Hauliers',
            sort: true,
            formatter: (data, index) => {
                return data?.consignment.hauliers ? (
                    data?.consignment.hauliers.map((haulier, index) => {
                        const { type, drivers, vehicle_id, name, registration } = haulier;
                        return (<>
                            Manfreight
                                <hr />
                            <ul key={`${vehicle_id}-${index}`} className={'d-flex flex-column gap'}>
                                <li><strong>Driver(s): </strong>
                                    <ul>
                                        {drivers.map((driver, index) => (
                                            <li key={`${driver.id}-${index}`}>{driver.name}</li>
                                        ))}
                                    </ul>
                                </li>
                                <li><strong>{type.charAt(0).toUpperCase() + type.slice(1)} : </strong> {vehicle_id}</li>
                                <li><strong>Registration : </strong> {registration}</li>
                                {index !== data?.consignment.hauliers.length - 1 ? <hr /> : <></>}
                            </ul></>
                        )
                    })
                ) : <></>
            }
        },
        {
            dataField: 'data',
            text: 'Trailers',
            sort: true,
            formatter: (data, index) => {
                return data?.consignment.containers ? (
                    data?.consignment.containers.map((smartSeal, index) => {
                        const { type, weight, capacity, registration } = smartSeal;
                        return (
                            <ul key={`${type}-${weight}-${index}`} className={'d-flex flex-column gap'}>
                                <li><strong>Type : </strong> {type}</li>
                                <li><strong>Weight : </strong> {weight}</li>
                                <li><strong>Capacity : </strong> {capacity}</li>
                                {index !== data?.consignment.containers.length - 1 ? <hr /> : <></>}
                            </ul>
                        )
                    })
                ) : <></>
            }
        },
        {
            dataField: 'data.journey.timereceived',
            text: 'Created At',
            sort: true,
            filter: dateFilter(),
        },
    ];

    const chartData = [
        {
            day: 'Sunday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Monday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Tuesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Wednesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Thursday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Friday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Saturday',
            sale: rn(4000),
            cost: rn(4000),
        },
    ];

    const chartData1 = [
        {
            day: 'Sunday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Monday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Tuesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Wednesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Thursday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Friday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Saturday',
            sale: rn(4000),
            cost: rn(4000),
        },
    ];

    const chartData2 = [
        {
            day: 'Sunday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Monday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Tuesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Wednesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Thursday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Friday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Saturday',
            sale: rn(4000),
            cost: rn(4000),
        },
    ];

    const analyticsStatData = [
        {
            id: '1',
            title: 'Active Journeys / Journeys',
            metric: '100',
            info: 'Total number of active journeys',
            increased: true,
            decreased: false,
            fill: '#015DE1',
            chart: chartData,
        },
        {
            id: '2',
            title: 'In Customs',
            metric: '12',
            info: 'Total transports currently in customs',
            increased: false,
            decreased: true,
            fill: '#B92E5D',
            chart: chartData1,
        },
        {
            id: '3',
            title: 'CO2',
            metric: '440,151 MT',
            info: 'Metric Tonnes of CO2 emissions on completed journeys.',
            increased: true,
            decreased: false,
            fill: '#048848',
            chart: chartData2,
        }
    ];

    const getJourneys = async () => {
        try {
            const { error, data: { data } } = await axios.get('/journey?page=1&items=1000');
            if (!error) {
                const uniqueJourneysData = data.reverse().reduce((journeys, journey) => {
                    if (!journeys.some((f) => f.data.json.id === journey.data.json.id)) {
                        journeys.push({ ...journey, timereceived: moment(journey.timereceived * 1000).format('YYYY-MM-DD HH:MM:SS') });
                    }
                    return journeys;
                }, [])
                const mergedJourneyData = [];
                for (const journey of uniqueJourneysData) {
                    const { txid, timereceived, keys, data: { json } } = journey;
                    const { errorConsignments, data: { data: [consignmentData] } } = await axios.get(`/consignment/transactions-by-key/journey-${json.id}?page=1&items=1000`);
                    const { errorEvents, data: { data: eventsData } } = await axios.get(`/event/transactions-by-keys/?keys=journey-${json.id},status&page=1&items=1000`);
                    const journeyStatus = eventsData[eventsData.length -1]?.data.json.metadata.status;
                    mergedJourneyData.push({
                        data: {
                            journey: {
                                ...json,
                                txid: txid,
                                journeyStatus,
                                timereceived: timereceived,
                            },
                            consignment: consignmentData.data.json
                        }
                    })
                }
                setFullJourneysData(mergedJourneyData);
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        getJourneys().catch(console.error);
    }, [])

    useEffect(() => {
    }, [fullJourneysData])

    return (
        <div id="field">
            <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3'}>
                <div className="mt-4 grid grid-cols-3 justify-around gap-6 @sm:py-3 @md:mt-3 @xl:mt-4 @7xl:gap-8">
                    <StatCards data={analyticsStatData} />
                </div>
                <div className="mt-4 grid grid-cols-3 justify-around gap-6 @sm:py-3 @md:mt-3 @xl:mt-4 @7xl:gap-8">
                    <div className='text-center'>
                        <Forecast
                            labels={[{ name: "Delivered", color: "#00D1FF" }, { name: "In Transit", color: "#6B46FF" }]}
                            description={`100,000,000 Tonnes`} title="Total Goods Volume" data={forecast}
                            className="chart-4" key1="In Transit" key2="Delivered" />
                    </div>

                    <div className='text-center'>
                        <Acquisition description={`11501 Journeys`}
                            title={"Journeys in 2023"} data={acquisition}
                            className="chart-4" />
                    </div>
                    <div className='text-center'>
                        <DeviceSessions description={"600.000 MT"} title="CO2 Emissions from last quarter"
                            data={deviceSessions} className="chart-4" />
                    </div>
                </div>
            </WidgetCard>
            <div className={"d-flex mt-3 mb-n2 pl-4 border-0"}>
                <h1>Journeys</h1>
            </div>
            <WidgetCard className={'mt-2 p-0 border-0'}>
                <div className="grid grid-cols-1">
                    {
                        (fullJourneysData && fullJourneysData.length) ?
                            <WidgetCard className={'m-3'}>
                                <BootstrapTable
                                    fluid
                                    bootstrap4
                                    keyField='data.journey.timereceived'
                                    columns={columns}
                                    data={fullJourneysData}
                                    filter={filterFactory()}
                                    filterPosition="top"
                                    pagination={paginationFactory()}
                                />
                            </WidgetCard> : <></>
                    }
                </div>
            </WidgetCard>
        </div>
    )
}

export default JourneyDashboard;