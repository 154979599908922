import { useEffect, useState, useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { useNavigate, useLocation } from "react-router-dom";

import StatCards from './../../components/Card/stat-cards';
import WidgetCard from './../../components/Card/widget-card';
import Forecast from './../../components/Chart/forecast';
import DeviceSessions from './../../components/Chart/device-sessions';
import Acquisition from './../../components/Chart/acquisition';
import moment from 'moment';
import QuickAccess from './../../components/Files/custom/quick-access';
import TransactionHistoryContainer from '../../components/Shared/TransactionHistoryContainer';
import HistoryStory from '../../components/HistoryStory';
import { Nav } from 'react-bootstrap';

import './theme.css';
import {statusBadgeClasses, titleCase} from "../../utils";

const SingleJourneyDashboard = () => {

    const { state } = useLocation();
    const navigate = useNavigate();
    const { axios } = useContext(GeneralContext);

    const [data, setData] = useState(null);
    const [statusEvent, setStatusEvent] = useState(null);
    const [documents, setDocuments] = useState([])
    const [consignmentData, setConsignmentData] = useState(null);
    const [eventDocuments, setEventDocuments] = useState(null);
    const [journeyEvents, setJourneyEvents] = useState(null);
    const [journeyId, setJourneyId] = useState(state ? state.journeyId : null);

    const getJourneyEvents = async () => {
        if (!journeyId) return;
        let formattedJourneyEvent = {};
        try {
            const { error: fieldError, data: { data: fieldEvent } } = await axios.get(`/event/transactions-by-key/journey-${journeyId}?page=1&items=1000`);
            formattedJourneyEvent = fieldEvent.reduce((acc, eventData, index) => {
                const { txid, timereceived, keys, data: { json: { event_leg_id, inject_location, file, ...details } } } = eventData;
                const { name } = details;
                const key = acc[name] ? `${name} ${index}` : name;
                return { ...acc, [key]: { txid, timereceived, data: { ...details } } };
            }, {})

            const statusEvents = fieldEvent.filter((e) => e.keys.includes('status'));
            setStatusEvent(statusEvents[statusEvents.length - 1]);
        } catch (error) {

        }

        let formattedEventDocuments = {};
        try {

            const { error, data: { data: documentsData } } = await axios.get(`/journey-document-metadata/transactions-by-key/journey-${journeyId}?page=1&items=1000`);
            formattedEventDocuments = {
                ...formattedEventDocuments,
                ...documentsData.reduce((acc, documentData) => {
                    const { txid, timereceived, keys, data: { json: details } } = documentData;
                    const { document_id } = details;
                    return { ...acc, [document_id]: { source: 'journey', txid, timereceived, data: { ...details } } };
                }, {})
            };
            setEventDocuments(formattedEventDocuments);
        } catch (error) {
            // Handle error
        }



        setJourneyEvents({ ...formattedJourneyEvent });
    }

    const rnmin = (min, max) => (Math.random() * (max - min) + min);
    const rn = (max) => (Math.floor(Math.random() * max));


    const forecast = [
        {
            key: 'Monday',
            "In Transit": rnmin(1, 10).toFixed(0),
            "Delivered": rnmin(10, 40).toFixed(0),
        },
        {
            key: 'Thursday',
            "In Transit": rnmin(1, 10).toFixed(0),
            "Delivered": rnmin(10, 10).toFixed(0),
        },
        {
            key: 'Friday',
            "In Transit": rnmin(1, 10).toFixed(0),
            "Delivered": rnmin(10, 20).toFixed(0),
        }

    ];

    const acquisition = [
        {
            day: 'Monday',
            lock: rnmin(1, 2).toFixed(0),
            unlock: rnmin(1, 2).toFixed(0),
        },
        {
            day: 'Thursday',
            lock: rnmin(1, 2).toFixed(0),
            unlock: rnmin(1, 2).toFixed(0),
        },
        {
            day: 'Friday',
            lock: rnmin(1, 2).toFixed(0),
            unlock: rnmin(1, 2).toFixed(0),
        }
    ];

    const deviceSessions = [
        {
            day: 'Mon',
            emitted: rnmin(600, 700).toFixed(0),
        },
        {
            day: 'Tue',
            emitted: rnmin(500, 600).toFixed(0),
        },
        {
            day: 'Thu',
            emitted: rnmin(400, 500).toFixed(0),
        },
        {
            day: 'Wed',
            emitted: rnmin(300, 400).toFixed(0),
        },
        {
            day: 'Fri',
            emitted: rnmin(200, 300).toFixed(0),
        },
        {
            day: 'Sun',
            emitted: rnmin(100, 200).toFixed(0),
        },
    ];

    const getDocuments = async () => {
        const { error, data: { data } } = await axios.get(`/journey-document-metadata/transactions-by-key/journey-${journeyId}?page=1&items=10`);
        setDocuments(data);
    }

    useEffect(() => {
        if (journeyId === null) {
            navigate('/');
        } else {
            const fetchOilJourneyDocuments = async () => {
                await getDocuments();
            }
            fetchOilJourneyDocuments().catch(console.error);
        }
    }, [])

    useEffect(() => { }, [data, documents])

    useEffect(() => {
        const fetchJourney = async () => {
            await getJourney();
        }
        fetchJourney().catch(console.error);
        const fetchConsignment = async () => {
            await getConsignment();
        }
        fetchConsignment().catch(console.error);
        const fetchJourneyEventsData = async () => {
            await getJourneyEvents();
        }
        fetchJourneyEventsData().catch(console.error);
    }, [journeyId]);

    const [fullJourneysData, setFullJourneysData] = useState([]);

    const chartData = [
        {
            day: 'Sunday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Monday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Tuesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Wednesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Thursday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Friday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Saturday',
            sale: rn(4000),
            cost: rn(4000),
        },
    ];

    const chartData1 = [
        {
            day: 'Sunday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Monday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Tuesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Wednesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Thursday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Friday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Saturday',
            sale: rn(4000),
            cost: rn(4000),
        },
    ];

    const chartData2 = [
        {
            day: 'Sunday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Monday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Tuesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Wednesday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Thursday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Friday',
            sale: rn(4000),
            cost: rn(4000),
        },
        {
            day: 'Saturday',
            sale: rn(4000),
            cost: rn(4000),
        },
    ];

    const analyticsStatData = [
        {
            id: '1',
            title: 'Stops',
            metric: '10',
            info: 'Total number of journey stops',
            increased: true,
            decreased: false,
            fill: '#015DE1',
            chart: chartData,
        },
        {
            id: '2',
            title: 'In Customs',
            metric: '5',
            info: 'Total customs checks for transport',
            increased: false,
            decreased: true,
            fill: '#B92E5D',
            chart: chartData1,
        },
        {
            id: '3',
            title: 'Estimated CO2',
            metric: '4 MT',
            info: 'Metric Tonnes of CO2 emissions estimated for this journey.',
            increased: true,
            decreased: false,
            fill: '#048848',
            chart: chartData2,
        }
    ];

    const getJourney = async () => {
        const { data: { data } } = await axios.get(`/journey/transactions-by-key/journey-${journeyId}?page=1&items=1000`);
        setData(data[0])
    }

    const getConsignment = async () => {
        const { data: { data } } = await axios.get(`/consignment/transactions-by-key/journey-${journeyId}?page=1&items=1000`);
        setConsignmentData(data[0])
    }

    useEffect(() => {
    }, [fullJourneysData])

    return (
        <div id="journey">
            <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3'}>
                <div className="mt-4 grid grid-cols-3 justify-around gap-6 @sm:py-3 @md:mt-3 @xl:mt-4 @7xl:gap-8">
                    <StatCards data={analyticsStatData} />
                </div>
                <div className="mt-4 grid grid-cols-3 justify-around gap-6 @sm:py-3 @md:mt-3 @xl:mt-4 @7xl:gap-8">
                    <div className='text-center'>
                        <Forecast
                            labels={[{ name: "Delivered", color: "#00D1FF" }, { name: "In Transit", color: "#6B46FF" }]}
                            description={`25 Tonnes`} title="Total Goods Volume" data={forecast}
                            className="chart-4" key1="In Transit" key2="Delivered" />
                    </div>

                    <div className='text-center'>
                        <Acquisition description={`15 Seal Events`}
                            title={"Seal Events"} data={acquisition}
                            className="chart-4" />
                    </div>
                    <div className='text-center'>
                        <DeviceSessions description={"10 MT"} title="CO2 Emissions "
                            data={deviceSessions} className="chart-4" />
                    </div>
                </div>
            </WidgetCard>
            <div className={"d-flex my-3 px-3 border-0 align-items-center"}>
                <h1>Journey: {journeyId}</h1>
                {data ? <small className={'ml-3'}>{data?.txid}</small> : null}
            </div>
            {consignmentData ? <div className={"d-flex my-3 px-3 border-0 align-items-center"}>
                <h5 className="d-flex justify-content-center align-items-center">Consignment:
                    <Nav.Link onClick={() => {
                        navigate(`/`, {
                            state: {
                                id: journeyId
                            }
                        });
                    }}
                    >
                        {consignmentData.data.json.reference}
                    </Nav.Link></h5>
                <small className={'ml-3'}>{consignmentData?.txid}</small>
            </div> : <></>}
            {statusEvent ? <div className={"d-flex my-3 px-3 border-0 align-items-center"}>
                <h5 className="d-flex justify-content-center align-items-center">
                    <span>Status:</span>
                    <span className={`badge ${statusBadgeClasses[statusEvent.data.json.metadata.status]}`}>{titleCase(statusEvent.data.json.metadata.status)}</span></h5>
                <small className={'ml-3'}>{statusEvent?.txid}</small>
            </div> : <></>}
            {statusEvent && !["completed", "rejected", "loading"].includes(statusEvent.data.json.metadata.status) ?
                <Nav.Link onClick={() => {
                        navigate(`/end-journey`, {
                            state: {
                                id: journeyId
                            }
                        });
                    }}
                          className={"btn btn-primary"}
                    >
                        Unload Consignment Form
                    </Nav.Link> : <></>
            }
            <hr />
            <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3'}>
                {documents.length ?
                    <div>
                        <QuickAccess stream="journey" withIcons={true} title="Journey Documents" id={`journey-${journeyId}`}
                            files={documents} /></div> : <></>}
            </WidgetCard>
            <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3'}>
                {journeyEvents && journeyId ? <HistoryStory isJourney={true} events={journeyEvents} documents={eventDocuments} /> : <></>}
            </WidgetCard>
        </div>
    )
}

export default SingleJourneyDashboard;