import React, { useContext, useState } from 'react';
import { Popup } from 'react-map-gl';
import MapView from './../index';
import { DataContext } from '../../../context/DataContext';
import VesselPopup from './vessel-popup';
import VesselMarker from './vessel-marker';
import { Card, Button } from 'react-bootstrap';

const GeofencePopup = ({ marker, markerDetails, setMarkerDetails }) => {

    const { mapDefaultStyle } = useContext(DataContext);

    const { area: areas, lat, lon, id, icon, stream, payload: name, vessel } = marker;

    const [vesselDetails, setVesselDetails] = useState(vessel);

    return (markerDetails && markerDetails.id === id) &&
        <Popup className='geo-popup geo-popup-width p-0' id={id} key={`vessel-${id}`} latitude={lat} longitude={lon} onClose={() => setMarkerDetails(null)} closeOnClick={false}  >
            <Card className="w-100">
                <Card.Title className="text-center pt-1 m-1">
                    {name}
                </Card.Title>
                {
                    vessel !== null ? (
                        <Card.Body className="p-0">
                            <div className="m-1 p-1 border border-default">
                                <MapView
                                    viewport={{ latitude: lat, longitude: lon, zoom: 16 }}
                                    setViewport={{ latitude: lat, longitude: lon, zoom: 16 }}
                                    mapElements={{ areas }}
                                    mapType="mapbox://styles/mapbox/satellite-streets-v12"
                                    renderActions={false}
                                >
                                    <VesselMarker marker={vessel} markerDetails={vesselDetails} setMarkerDetails={setVesselDetails} />
                                </MapView >
                            </div>
                            <Card.Text>
                                <ul>
                                    {Object.keys(vessel.payload).map((key, index) => {
                                        return (
                                            <li key={`${key}-${index}`}><strong>{key.charAt(0).toUpperCase() + key.slice(1)}</strong> : {vessel.payload[key]}</li>
                                        )
                                    })}
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    ) : <></>
                }
            </Card>
        </Popup>
}

export default GeofencePopup