import { useEffect, useState, useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { DataContext } from '../../context/DataContext';
import MapView from './../../components/Map/index';
import { formatAreaCollectionFromGeofences, titleCase } from './../../utils';
import HaulierPopup from './../../components/Map/elements/haulier-popup';
import GeofencePopup from './../../components/Map/elements/geofence-popup';
import MapMarker from './../../components/Map/elements/marker';
import { Form, Nav } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";

import './theme.css';

const Dashboard = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const { axios, env } = useContext(GeneralContext);
    const { mapDefaultLatitude, mapDefaultLongitude, mapDefaultStyle } = useContext(DataContext);

    const [mapElements, setMapElements] = useState({ markers: [], areas: null });
    const [viewport, setViewport] = useState({ latitude: mapDefaultLatitude, longitude: mapDefaultLongitude, zoom: 6 });
    const [markerDetails, setMarkerDetails] = useState(null);
    const [selectedJourney, setSelectedJourney] = useState(state ? state.id : false);
    const [availableJourneys, setAvailableJourneys] = useState([]);
    const [availableMarkers, setAvailableMarkers] = useState([
        'geo',
        'truck',
        'dock',
        'PING',
        'SMARTSEAL_UNLOCKED',
        'SMARTSEAL_LOCKED',
        'EXIT_GEOFENCE',
        'ENTER_GEOFENCE',
        'route'
    ]);
    const [selectedMarkers, setSelectedMarkers] = useState([
        'geo',
        'truck',
        'dock',
        'PING',
        'SMARTSEAL_UNLOCKED',
        'SMARTSEAL_LOCKED',
        'EXIT_GEOFENCE',
        'ENTER_GEOFENCE',
        'route'
    ]);
    const rnmin = (min, max) => (Math.random() * (max - min) + min);

    const getAllJourneys = async () => {
        const { error: journeysListingError, data: { data: journeysData } } = await axios.get(`/journey?page=1&items=1000`);
        const journeys = [];
        for (const journey of journeysData) {
            const { txid, keys, data: { json: { id } } } = journey;
            const { errorConsignment, data: { data: [consignmentData] } } = await axios.get(`/consignment/transactions-by-key/journey-${id}?page=1&items=1000`);
            journeys.push({ id, reference: consignmentData.data.json.reference });
        }
        setAvailableJourneys(journeys);
    }

    const getHaulierLocations = async (journeyId) => {

        let journeysResults;
        if (!!selectedJourney) {
            journeysResults = await axios.get(`/journey/transactions-by-key/journey-${journeyId}?page=1&items=1000`);

            const { error: journeysListingError, data: { data: journeysData } } = journeysResults;

            const mapMarkersList = {
                markers: [],
                areas: {
                    "type": "FeatureCollection",
                    features: []
                },
                routes: {
                    complete: {
                        "type": "FeatureCollection",
                        features: []
                    },
                    incomplete: {
                        "type": "FeatureCollection",
                        features: []
                    }
                }
            }

            let routeSplit = false;
            let shouldSplit = true;
            if (!journeysListingError) {
                for (const journey of journeysData) {
                    const { txid, keys, data: { json: { id } } } = journey;
                    const { errorEvents, data: { data: markersData } } = await axios.get(`/event/transactions-by-key/journey-${id}?page=1&items=1000`);
                    const totalPings = markersData.map(e => (e.data.json.event === 'PING')).filter(Boolean).length
                    let pingCount = 0;
                    markersData.map(({ data: { json: { coordinates, event_leg_id, inject_location, timestamp, event, ...details } } }, index) => {
                        shouldSplit = event !== 'JOURNEY_ENDED'
                        if (coordinates) {

                            if (event === 'PING') {
                                pingCount++;
                            }

                            const [lon, lat] = coordinates;
                            const eventMarker = {
                                payload: details,
                                lat,
                                lon,
                                id: timestamp,
                                type: 'marker',
                                icon: "journey",
                                className: (totalPings === pingCount && event === 'PING') ? 'truck' : event,
                                stream: "event",
                                txid
                            }

                            if ((totalPings === pingCount && event === 'PING')) {
                                routeSplit = eventMarker;
                            }

                            mapMarkersList.markers.push(eventMarker);
                        }

                    });

                    const { errorConsignment, data: { data: [consignmentData] } } = await axios.get(`/consignment/transactions-by-key/journey-${id}?page=1&items=1000`);

                    const { errorRoutes, data: { data: [routeData] } } = await axios.get(`/route/transactions-by-key/route-${id}?page=1&items=1000`);

                    if (!errorRoutes) {
                        const { data: { json: { vessels, geofences, ...restOfRouteData } } } = routeData;

                        const { txId: routeTxId, id: route_id, path_id } = restOfRouteData;

                        const { routePathError, data: { data: routePath } } = await axios.get(`/path/${path_id}`);
                        let mergedGeometry = routePath.features;
                        if (!routePathError) {
                            if (routeSplit && shouldSplit) {
                                let coreIndex = 0;
                                mergedGeometry = routePath.features.reduce((acc, { geometry: { coordinates } }, index) => {
                                    const { lat, lon } = routeSplit;
                                    coordinates.map((c, i) => {
                                        if (!!coreIndex) {
                                            acc.incomplete.geometry.coordinates.push(c);
                                        } else {
                                            const hasMatch = JSON.stringify(c) === JSON.stringify([lon, lat]);
                                            if (hasMatch) {
                                                acc.incomplete.geometry.coordinates.push(c);
                                                coreIndex++;
                                            } else {
                                                acc.complete.geometry.coordinates.push(c);
                                            }
                                        }
                                    })

                                    return acc;
                                }, {
                                    "complete": {
                                        type: 'Feature',
                                        geometry: {
                                            type: 'LineString',
                                            coordinates: []
                                        }
                                    },
                                    "incomplete": {
                                        type: 'Feature',
                                        geometry: {
                                            type: 'LineString',
                                            coordinates: []
                                        }
                                    }
                                });
                                mapMarkersList.routes.complete.features = [...mapMarkersList.routes.complete.features, mergedGeometry.complete,];
                                mapMarkersList.routes.incomplete.features = [...mapMarkersList.routes.incomplete.features, mergedGeometry.incomplete];
                            } else {
                                mapMarkersList.routes.complete.features = [...routePath.features];
                            }
                        }

                        geofences.map(({ coordinates, name, center: { lat, lng: lon } }, index) => {
                            const markerVessel = vessels.find(vessel => ((vessel.origin === name) || (vessel.destination === name)));
                            const routeMarker = {
                                payload: name,
                                vessel: markerVessel ? {
                                    payload: markerVessel,
                                    lat,
                                    lon,
                                    id: `${name}`,
                                    type: name.includes('Port') ? 'dock' : 'building',
                                    icon: "vessel",
                                    className: 'vessel',
                                    stream: "route",
                                    txid: routeTxId
                                } : {
                                    payload: { name },
                                    lat,
                                    lon,
                                    id: `${name}`,
                                    type: name.includes('Port') ? 'dock' : 'building',
                                    icon: "vessel",
                                    className: name.includes('Port') ? 'dock' : 'building',
                                    stream: "route",
                                    txid: routeTxId
                                },
                                area: {
                                    "type": "FeatureCollection",
                                    features: [{
                                        "type": "Feature",
                                        "properties": {
                                            "name": name,
                                        },
                                        "geometry": {
                                            "type": "Polygon",
                                            "coordinates": [coordinates]
                                        }
                                    }]
                                },
                                lat,
                                lon,
                                id: `${route_id}-${index}`,
                                type: 'geo',
                                icon: "geo",
                                className: markerVessel ? 'dock' : 'geo',
                                stream: "journey",
                                txid: routeTxId
                            }
                            mapMarkersList.markers.push(routeMarker);

                        });
                        mapMarkersList.areas.features = [...mapMarkersList.areas.features, ...formatAreaCollectionFromGeofences(geofences)];
                    }

                }
            }
            setMapElements(mapMarkersList);
        } else {
            setMapElements({ markers: [], areas: null });
        }
    }


    useEffect(() => {
        const fetchHaulierLocationsData = async () => {
            await getHaulierLocations(selectedJourney);
        }
        fetchHaulierLocationsData().catch(console.error);

        const fetchJourneysData = async () => {
            await getAllJourneys();
        }
        fetchJourneysData().catch(console.error);
    }, [])

    useEffect(() => {
        const fetchHaulierLocationsData = async () => {
            await getHaulierLocations(selectedJourney);
        }
        fetchHaulierLocationsData().catch(console.error);
    }, [selectedJourney])

    useEffect(() => {
    }, [mapElements, availableJourneys, selectedMarkers, selectedJourney])

    const markerPopupType = (type, marker) => {
        switch (type) {
            case 'geo':
                return <GeofencePopup marker={marker} markerDetails={markerDetails} setMarkerDetails={setMarkerDetails} />
            default:
                return <HaulierPopup marker={marker} markerDetails={markerDetails} setMarkerDetails={setMarkerDetails} />
        }
    }
    const MarkerList = () => {
        return mapElements.markers ?
            mapElements.markers.filter(m => (selectedMarkers.includes(m.className))).filter(Boolean).map((marker, index) => {
                const { type } = marker;
                return (
                    <div key={`markers-list-${index}`}>
                        {markerPopupType(type, marker)}
                        <MapMarker marker={marker} setViewport={setViewport} markerDetails={markerDetails} setMarkerDetails={setMarkerDetails} />
                    </div>
                );
            }) : <></>;
    }

    return (
        <>
            <MapView
                viewport={viewport}
                setViewport={setViewport}
                mapElements={selectedMarkers.includes('route') ? mapElements : { ...mapElements, routes: { ...mapElements.routes, features: [] } }}
                mapType={mapDefaultStyle}
            >
                <MarkerList />
            </MapView >
            <div className="pabr-filters text-center">
                <div className='col-12'>
                    <div className='row p-0 m-0'>
                        <div className='col-4 p-2 d-flex flex-row'>
                            <select
                                onChange={(e) => {
                                    setSelectedJourney(e.target.value === 'false' ? false : e.target.value);
                                }}
                                id="inputState" className="form-control w-50">
                                <option value={false} >Select Consignment</option>
                                {
                                    availableJourneys.map(({ id, reference }) => {
                                        return (<option key={reference} value={id} selected={selectedJourney == id}>{reference}</option>);
                                    })
                                }
                            </select>
                            {
                                !!selectedJourney ?
                                    (
                                        <div onClick={() => {
                                            navigate(`/journey-data`, {
                                                state: {
                                                    journeyId: selectedJourney
                                                }
                                            });
                                        }} className="btn btn-warning w-100 h-10 ml-1">View Consignment Details</div>
                                    ) : <></>
                            }
                        </div>
                        <div className='col-8 p-2'>
                            {!!selectedJourney ?
                                <div className="filter-checkbox pt-1">
                                    <span className={"align-self-center mr-2"}><strong>Toggle Markers: </strong></span>
                                    {
                                        selectedMarkers.length === availableMarkers.length ?
                                            <div className={"w-8 h-8 mx-2 marker select-none"} title={"Select None"} onClick={() => setSelectedMarkers([])} />
                                            :
                                            <div className={"w-8 h-8 mx-2 marker select-all"} title={"Select All"} onClick={() => setSelectedMarkers([...availableMarkers])} />

                                    }
                                    {
                                        availableMarkers.map((marker) => {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        if (selectedMarkers.includes(marker)) {
                                                            const index = selectedMarkers.indexOf(marker);
                                                            if (index !== -1) {
                                                                selectedMarkers.splice(index, 1);
                                                            }
                                                            setSelectedMarkers([...selectedMarkers]);
                                                        } else {
                                                            setSelectedMarkers([...selectedMarkers, marker]);
                                                        }
                                                    }}
                                                    className={`mx-2 w-8 h-8 marker ${marker} ${selectedMarkers.includes(marker) ? "" : "marker-not-selected"}`}
                                                    title={titleCase(marker.toLowerCase().split('_').join(' '))}
                                                />
                                            );
                                        })
                                    }
                                </div> : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Dashboard;